import React, { FC } from "react";
import { LazyImage } from "../Helpers/LazyImage";
import { RedirectButton } from "../Shared/RedirectButton";

export const BlogsList: FC<{ blogPosts: any }> = ({ blogPosts }) => {
  return blogPosts.map(
    ({
      id,
      title,
      shortDescription,
      slug,
      blogPoster: {
        file: { url },
      },
    }) => {
      return (
        <div className="col-md-6 col-lg-4 mb-5" key={id}>
          <blockquote className="quote-modern blog-card-container">
            <div className="blog-list-img-container">
              <LazyImage alt={title} height={210} width={370} src={url} />
            </div>

            <div className="quote-modern-main p-3">
              <h4 className="cut-text1" title={title}>
                {" "}
                {title}{" "}
              </h4>
              <p className="mt-3 cut-text4 text-dark">{shortDescription}</p>
              <div className="row align-items-center justify-content-center mt-4 blog-redirect-btn">
                <RedirectButton path={slug} title="Read more" type="Aqua" />
              </div>
            </div>
          </blockquote>
        </div>
      );
    },
  );
};
